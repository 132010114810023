function startOther(string) {

  AOS.init({
    duration: 800,
    easing: 'slide'
  });

  (function ($) {
    $(window).stellar({
      responsive: true,
      parallaxBackgrounds: true,
      parallaxElements: true,
      horizontalScrolling: false,
      hideDistantElements: false,
      scrollProperty: 'scroll'
    });



    var fullHeight = function () {
      $('.js-fullheight').css('height', $(window).height());
      $(window).resize(function () {
        $('.js-fullheight').css('height', $(window).height());
      });

    };
    fullHeight();

    // loader
    var loader = function () {
      setTimeout(function () {
        if ($('#ftco-loader').length > 0) {
          $('#ftco-loader').removeClass('show');
        }
      }, 1);
    };
    loader();

    // Scrollax
    $.Scrollax();

    // var carousel = function () {
    //   $('.carousel-testimony').owlCarousel({
    //     center: true,
    //     loop: true,
    //     items: 1,
    //     margin: 30,
    //     stagePadding: 0,
    //     nav: false,
    //     navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
    //     responsive: {
    //       0: {
    //         items: 1
    //       },
    //       400: {
    //         items: 2
    //       },
    //       800: {
    //         items: 3
    //       },
    //       1200: {
    //         items: 4
    //       }
    //     }
    //   });

    // };
    // carousel();




    var counter = function () {

      $('#section-counter, .hero-wrap, .ftco-counter').waypoint(function (direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {

          var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(',')
          $('.number').each(function () {
            var $this = $(this),
              num = $this.data('number');
            $this.animateNumber({
              number: num,
              numberStep: comma_separator_number_step
            }, 1000);
          });

        }

      }, {
        offset: '95%'
      });

    }
    counter();

    var contentWayPoint = function () {
      var i = 0;
      $('.ftco-animate').waypoint(function (direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {

          i++;

          $(this.element).addClass('item-animate');
          setTimeout(function () {

            $('body .ftco-animate.item-animate').each(function (k) {
              var el = $(this);
              setTimeout(function () {
                var effect = el.data('animate-effect');
                if (effect === 'fadeIn') {
                  el.addClass('fadeIn ftco-animated');
                } else if (effect === 'fadeInLeft') {
                  el.addClass('fadeInLeft ftco-animated');
                } else if (effect === 'fadeInRight') {
                  el.addClass('fadeInRight ftco-animated');
                } else {
                  el.addClass('fadeInUp ftco-animated');
                }
                el.removeClass('item-animate');
              }, k * 50, 'easeInOutExpo');
            });

          }, 100);

        }

      }, {
        offset: '95%'
      });
    };
    contentWayPoint();
    console.clear();

    Splitting({
      target: '.tiler',
      by: 'cells',
      rows: 3,
      columns: 3,
      image: true
    }); //Image('.tiler-overlay', { rows: 3, cols: 3 });



    $('.tab-link').click(function () {

      var tabID = $(this).attr('data-tab');

      $(this).addClass('active').siblings().removeClass('active');

      $('#tab-' + tabID).addClass('active').siblings().removeClass('active');
    });

    $('html, body').animate({
      scrollTop:790
    }, 1500);

  })(jQuery);
}
function validateForm(){
  var fname = $('#c-name').val();
  if(!validateString(fname)){
    return false;
  }

  var fphone = $('#c-phone').val();

  var femail = $('#c-email').val();
  if(!validateEmail(femail)){
    return false;
  };

  var fconfirmemail = $('#c-confirmemail').val();
  if(!validateEmail(fconfirmemail)){
    return false;
  };

  var fquestions = $('#c-questions').val();
  if(!validateString(fquestions)){
    return false;
  }
}

function validateString(string){
  if(string.length > 0){
    return true
  }
  return false;
}

function validateEmail(email) {
  var emform = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (emform.test(email)) {
    return true;
  } else {
    return false
  }
}

function googleActivate() {
  var map = new google.maps.Map(document.getElementById('map'), {
    center: {
      lat: -5.094073540034178,
      lng: -36.27461937318225
    },
    zoom: 17,
  });
  var marker = new google.maps.Marker({
    position: {
      lat: -5.09390168966581,
      lng: -36.27398814870342
    },
    map: map,
    title: 'Chalé Oasis'
  });

}
//-5.094073540034178, -36.27461937318225
